import {
  log_google_analytics,
  h_show_inline_alert,
  h_render_component,
  h_push_state,
  h_auto_callback,
  scrubObject,
  ajax_entry_point
} from '@lib/globals';

import GA from '@lib/ga';

/**
 *
 * This function fetches and renders all triggered events
 * It should never change because the data is all passed in from each triggering elements's attributes
 *
 * @param event
 *            Event with (data-)access and (data-)component references passed
 * @param original_target
 *            What triggered the state change
 * @param component_wrapper
 *            The enclosing component element in the DOM
 * @param args
 *            Object containing query args to send
 * @returns
 */

export default function(original_target) {
  var request_data = {},
    original_target = $(original_target),
    target_save_html = original_target.html(),
    feedback_element = original_target.attr('data-feedback-target'),
    icon = '<i class="fas fa-circle-notch fa-spin icon"></i>',
    container = original_target.attr('data-container') || original_target.parents('form'),
    operation = original_target.attr('data-operation-class'),
    // component targeted to show rendered results, i.e. "#updateGrid"
    rendering_target = $(original_target.attr('data-target')), // converting target to jquery obj
    unfiltered_input = $(container).find('.filtered-input:not(.disabled):not([name*="[]"])'),
    unfiltered_input_array = $(container).find('.filtered-input[name*="[]"]'),
    meta = original_target.attr('data-meta') ? JSON.parse(original_target.attr('data-meta')) : {},
    callback = original_target.attr('data-callback');

  request_data[ajax_obj.valid_args.INPUT_ACTION_TYPE] = ajax_obj.ajaxaction_types.OPERATION;
  request_data[ajax_obj.valid_args.INPUT_OPERATION_CLASS] = operation;
  request_data[ajax_obj.valid_args.INPUT_DATA_META] = meta;

  var array_of_input_arrays = [];
  unfiltered_input_array.each((idx, input) => {
    var name = $(input).attr('data-unfiltered-key');
    if (!array_of_input_arrays.includes(name)) {
      var sibling_elements = $(container).find('[data-unfiltered-key="' + name + '"]'),
        input_array = sibling_elements.map((idx, el) => {
          return el.value;
        });
      request_data[name] = input_array.get();
      array_of_input_arrays.push(name);
    }
  });

  $(unfiltered_input).each(function(idx, input) {
    var input = $(input),
      name = input.attr('data-unfiltered-key'),
      checkbox_values = {};
    if (
      input.is(
        'input[type="text"],input[type="range"],input[type="date"],input[type="number"],input[type="tel"],input[type="email"],input[type="url"],input[type="hidden"],textarea:not(.wp-editor-area)'
      )
    ) {
      request_data[name] = input.val();
    } else if (input.is('textarea.wp-editor-area')) {
      var tiny = container
        .find('.tinymce-helper')
        .attr('data-target')
        .replace('#', '');
      request_data[name] = tinyMCE.get(tiny).getContent();
    } else if (input.is('input[type="radio"]')) {
      if (input.is(':checked')) {
        request_data[name] = input.val();
      }
    } else if (input.is('select')) {
      request_data[name] = input.children('option:selected').val();
    }
  });

  // Which button, save or save as?
  var is_saving = original_target.is('[name^="save"]'),
    save_or_save_as = original_target.attr('data-unfiltered-key');
  if (is_saving && save_or_save_as) {
    request_data[save_or_save_as] = true;
  }

  // Handle checkboxes separately
  var all_checkboxes = $(container).find('input[type="checkbox"]'),
    checkboxMap = {}; // name => { values, ... }
  all_checkboxes.each(function(idx, cb) {
    var cb_name = $(cb).attr('data-unfiltered-key'),
      cb_value = $(cb).val(),
      cb_checked = $(cb).is(':checked');
    if (!cb_checked) return;
    if (checkboxMap[cb_name]) {
      checkboxMap[cb_name].push(cb_value);
    } else {
      checkboxMap[cb_name] = [cb_value];
    }
  });
  $.each(checkboxMap, function(i, e) {
    request_data[i] = e;
  });

  if (!$('body').is('.insert')) {
    var md5 = require('md5');
    // Add the hash to the this request_data
    request_data[ajax_obj.valid_args.INPUT_CACHE_KEY] = md5(JSON.stringify(request_data));
  }

  ajax_entry_point(
    request_data,
    function(xhr) {
      // beforeSend
      original_target.html(icon);
      // Push current page to history?
    },
    function(response, status) {
      // done
      if (ajax_obj.valid_responses.SUCCESS == status) {
        var operation_arr = operation.split('\\'),
          operation_class = operation_arr.pop();

        h_show_inline_alert(response, $(feedback_element), 'html', 'alert-success text-center', 'fadeInUp');

        log_google_analytics(original_target, function(element) {
          // Take out the junk
          const gtag_meta = {};
          $.each(request_data, (idx, val) => {
            if (
              idx.indexOf(ajax_obj.valid_args.INPUT_ACTION) !== -1 ||
              idx.indexOf(ajax_obj.valid_args.INPUT_WP_NONCE) !== -1 ||
              idx.indexOf(ajax_obj.valid_args.INPUT_ACTION_TYPE) !== -1 ||
              idx.indexOf(ajax_obj.valid_args.INPUT_OPERATION_CLASS) !== -1 ||
              idx.indexOf(ajax_obj.valid_args.INPUT_CACHE_KEY) !== -1 ||
              (idx.indexOf(ajax_obj.valid_args.INPUT_DATA_META) !== -1 && Object.keys(val).length < 1) ||
              !val ||
              val.length == 0
            ) {
              return true;
            }
            gtag_meta[idx] = val;
          });
          return GA.dispatch(
            original_target.attr('data-ga-event') || operation_class,
            'Operation', // category
            'submit', // action
            original_target.attr('data-ga-label') || original_target.attr('aria-label'), // label
            scrubObject(gtag_meta) // meta
          );
        });

        var parentForm = original_target.parents('form').attr('id');
        if (operation_class == 'OperationSave' && window.dropzoneInstance && dropzoneInstance[parentForm].isQueued()) {
          $(feedback_element)
            .find('.h-inline-alert')
            .append(': Uploading file ... please wait');
          dropzoneInstance[parentForm].uploadFiles(feedback_element);
        }

        if (response[ajax_obj.valid_responses.RESET_FORM] && original_target.parents('form').length > 0) {
          original_target.parents('form')[0].reset();
        }
        if (response[ajax_obj.valid_responses.RENDER]) {
          h_render_component(rendering_target, response[ajax_obj.valid_responses.RENDER], callback);
        }

        if (response[ajax_obj.valid_responses.PUSH_STATE_URL]) {
          h_push_state(response[ajax_obj.valid_responses.PUSH_STATE_URL], { name: 'searchURL' });
        }

        h_auto_callback(callback);
      } else {
        h_show_inline_alert(response, $(feedback_element), 'html', 'alert-danger text-center');
      }
    },
    function(response, textstatus, xhr) {
      // always
      original_target.html(target_save_html);
    },
    function(jqXHR, textStatus) {
      var response = {};
      response[ajax_obj.valid_responses.MSG] = 'Server error, please try again soon. ' + textStatus;
      response[ajax_obj.valid_responses.STATUS] = ajax_obj.valid_responses.FAILURE;
      h_show_inline_alert(response, $(feedback_element), 'html', 'alert-danger');
    },
    'POST'
  );
}
