export default function(triggering_evt, original_event, original_target) {
  var modal = $('#h-enlarge'),
    img = $(original_target),
    image_src = img.attr('data-full-image'),
    alt = img.attr('alt');
  $('#modal-image-placeholder').html(
    `<span class="close" data-dismiss="modal" aria-label="Close"><img class="img-fluid" alt="Enlarge work image" src="${image_src}" alt="${alt}"></span>`
  );
  modal.modal('show');
}
