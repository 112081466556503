import { validate_input_fields, h_alert, pd_error, pd_console } from '@lib/globals.js';
import { scrollToggleFunc } from './handleToggleScrollTop';

function lazyHandle(triggering_evt, original_event, original_target, module_name) {
  var passthrough_args = [triggering_evt, original_event, original_target];

  /* These are necessary for subsequent targeting */
  if ($(original_target).hasClass('h-triggers-operation')) {
    passthrough_args = handle_trigger_operation(original_target);
    if (!passthrough_args) {
      return false;
    }
  }
  if ($(original_target).hasClass('h-triggers-state-change')) {
    passthrough_args = handle_trigger_state_change(original_target);
  }

  // See if there are multiple modules per data-module=""
  const modules = module_name.split(',');

  $.each(modules, (idx, mod_name) => {
    import(
      /* webpackChunkName: "[request]" */
      `./${mod_name}`
    )
      .then(module => {
        const lazy = module.default;
        console.log('loaded ' + module_name);
        lazy(...passthrough_args);
      })
      .then(() => {
        if ($(original_target).attr('href')) {
          window.location.assign($(original_target).attr('href'));
        }
      })
      .catch(error => {
        pd_error(error);
      });
  });
}

/**
 * This function handles all "click" events that change component state and ultimately renders
 * Because lazy loading modules is good, for every click handler, just add class="triggers-lazy-module" and data-module="moduleFileNameWithoutExtension"
 * to the node. The following code with dispath and load it automatically.
 */
export function listeners() {
  /* Prevent submitting forms on hitting enter */
  $(window)
    .keydown(function(event) {
      if (event.keyCode == 13 && $(event.target).is('input')) {
        event.preventDefault();
        return false;
      }
    })
    /* Prevent dragging to window when using dropzone */
    .on('dragover', function(e) {
      e = e || event;
      e.preventDefault();
    })
    .on('drop', function(e) {
      e = e || event;
      e.preventDefault();
    })
    .on('scroll', scrollToggleFunc);

  $(document)
    .on('ModuleLazyLoad', { foo: 'bar' }, lazyHandle)
    .on('click', '.triggers-lazy-module', function(e) {
      var // what was clicked
        target = $(e.currentTarget),
        // component targeted to lazy load
        lazy_module = target.attr('data-module') + '.js';

      // no module? keep going
      if (!target.attr('data-module')) {
        return true;
      }

      // Check for ping
      let ping = target.attr('data-ping');
      if (ping) {
        $.ajax({
          url: ping,
          method: 'POST'
        })
          .done(function(response, textStatus, jqXHR) {
            pd_console( 'ping sent', ping, response );
          })
          .fail(function(jqXHR, textStatus, errorThrown) {
            pd_error( 'ping failed', jqXHR);
          });
      }

      e.preventDefault();
      e.stopPropagation();

      $(document).trigger('ModuleLazyLoad', [e, target, lazy_module]);
    })
    .on('click', '[class*="btn-"]:disabled,[class*="btn-"].disabled', function() {
      return false;
    });
}

function handle_trigger_operation(target) {
  var /*
        All inputs geared for sending to ajax must have the following:
        1) an operations class that handles the ajax callback
        2) a magic class on the input element named 'filtered-input'
        3) a 'data-unfiltered-key' attribute on the input element that matches the FilteredInput defined variable (also available in ajax_obj)
        4) 'h-triggers-operation' class on the triggering submit element
        5) 'data-container' attrubite on the triggering submit element encapsulating the fields to target
      */
    target = $(target);
  if (validate_input_fields(target.parents('form'), target)) {
    return target;
  }
  return false;
}

function handle_trigger_state_change(target) {
  var // what was clicked
    target = $(target),
    // component targeted to change state, i.e. "#updateGrid"
    component = target.attr('data-target'),
    // Callback if provided
    callback = target.attr('data-callback');

  return [target, component, callback];
}
