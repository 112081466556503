import {
  h_show_inline_alert,
  log_google_analytics,
  h_render_component,
  h_push_state,
  scrubObject,
  ajax_entry_point
} from '@lib/globals';

/**
 *
 * This function fetches and renders all triggered events
 * It should never change because the data is all passed in from each triggering elements's attributes
 *
 * @param event
 *            Event with (data-)access and (data-)component references passed
 * @param original_target
 *            What triggered the state change
 * @param component_wrapper
 *            The enclosing component element in the DOM
 * @param args
 *            Object containing query args to send
 * @returns
 */
export default function (original_target, component_wrapper, callback) {
  const request_data = {};

  request_data[ajax_obj.valid_args.INPUT_ACTION_TYPE] = ajax_obj.ajaxaction_types.COMPONENT;
  request_data[ajax_obj.valid_args.INPUT_COMPONENT] = $(component_wrapper).attr('data-component-class');
  request_data[ajax_obj.valid_args.INPUT_DATA_CLASS] = $(component_wrapper).attr('data-access-class');
  // query args to pass along, json
  request_data[ajax_obj.valid_args.INPUT_DATA_ARGS] = JSON.parse(original_target.attr('data-args'));
  // arbitrary metadata to pass along, json
  request_data[ajax_obj.valid_args.INPUT_DATA_META] = original_target.attr('data-meta')
    ? JSON.parse(original_target.attr('data-meta'))
    : {};

  if (original_target.attr('data-cache-key')) {
    request_data[ajax_obj.valid_args.INPUT_CACHE_KEY] = original_target.attr('data-cache-key');
  }

  ajax_entry_point(
    request_data,
    function(xhr) {
      // beforeSend
      original_target
        .parents('.h-thumb')
        .find('.h-thumb-image')
        .addClass('progress');
    },
    function(response, status) {
      // done
      if (ajax_obj.valid_responses.SUCCESS == status) {
        log_google_analytics(original_target, function(element) {
          var component_class = $(component_wrapper).attr('data-access-class'),
            class_arr = component_class.split('\\'),
            the_class = class_arr.pop(),
            the_page = original_target.attr('href') || original_target.siblings('a').attr('href'),
            data_args = original_target.attr('data-args') ? scrubObject( JSON.parse(original_target.attr('data-args')) ) : {},
            data_meta = original_target.attr('data-meta') ? scrubObject( JSON.parse(original_target.attr('data-meta')) ) : {},
            stripped_args = { ...data_args, ...data_meta };

          return GA.dispatch(
            original_target.attr('data-ga-event') || 'click', // event name
            the_class, // category
            'click', // action
            original_target.attr('data-ga-label') || original_target.attr('aria-label') || JSON.stringify(original_target.attr("data-args")), // label
            stripped_args || null // meta
          );
        });

        if (response[ajax_obj.valid_responses.PUSH_STATE_URL]) {
          h_push_state(response[ajax_obj.valid_responses.PUSH_STATE_URL], { name: 'searchURL' });
        }

        h_render_component(component_wrapper, response[ajax_obj.valid_responses.RENDER], callback);
        h_auto_callback(callback);
        assign_xhr_hashes();
      } else {
        h_show_inline_alert(response, $(component_wrapper), 'after');
      }
    },
    function(response, textstatus, xhr) {
      // always
      original_target
        .parents('.h-thumb')
        .find('.h-thumb-image')
        .removeClass('progress');
    },
    function(jqXHR, textStatus) {
      var response = {};
      response[ajax_obj.valid_responses.MSG] = 'Server error, please try again soon. ' + textStatus;
      response[ajax_obj.valid_responses.STATUS] = ajax_obj.valid_responses.FAILURE;
      h_show_inline_alert(response, $('.h-alert'), 'html', 'alert-danger');
    }
  );
}


