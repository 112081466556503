import {log_google_analytics} from '@lib/globals';
import GA from '@lib/ga';

export default function(triggering_evt, original_event, original_target) {
  var was_clicked = $(original_target),
    ga_event = was_clicked.attr('data-ga-event') || 'click',
    value = was_clicked.attr('data-ga-label') || was_clicked.attr('aria-label') || was_clicked.html(),
    event_category = was_clicked.attr('data-event-category') || 'Navigation',
    event_action = was_clicked.attr('data-event-action') || 'click',
    event_meta = was_clicked.attr('data-meta');
  log_google_analytics(was_clicked, function(element) {
    return GA.dispatch(ga_event, event_category, event_action, value, event_meta);
  });
}
